<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Docs" icon="folder" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>

  <q-card class="">
    <q-form  class="qForm">
      <q-card-section>
        <div class="text-h6">Docs</div>
      </q-card-section>

      <q-separator inset />

      <q-card-section class="column q-gutter-md content">
      <ul class="section">
        <span class="title">API Key</span>
        <span class="links"><br>
          <q-btn icon="download" color="altmedia-blue" label="Download Altmedia-SMS-HTTP-API-rev02.pdf" :href="`${REST_ENDPOINT}utils/pdf/Altmedia-SMS-HTTP-API-rev02.pdf`" target="_blank"></q-btn>
        </span>
      </ul>
      <ul class="section">
        <span class="title">Sender</span>
        <span class="description">RO SENDER REGISTRATION</span>
        <br>
        <q-btn icon="download" color="altmedia-blue" label="Download machetaRomania-Vodafone-Agreement.pdf" :href="`${REST_ENDPOINT}utils/pdf/machetaRomania-Vodafone-Agreement.pdf`" target="_blank"></q-btn>
        <br>
        <q-btn icon="download" color="altmedia-blue" label="Download machetaRomania-Orange-Agreement.pdf" :href="`${REST_ENDPOINT}utils/pdf/machetaRomania-Orange-Agreement.pdf`" target="_blank"></q-btn>
        <br>
        <q-btn icon="download" color="altmedia-blue" label="Download machetaRomania-Digi-Agreement.pdf" :href="`${REST_ENDPOINT}utils/pdf/machetaRomania-Digi-Agreement.pdf`" target="_blank"></q-btn>

      </ul>
    </q-card-section>

      <q-card-actions>
        
      </q-card-actions>
    </q-form>
  </q-card>
  
</template>

<script>
import { getRestEndpoint } from '@/utils/utils'

export default {
  name: 'DocsPage',

  data() {
    return {
      REST_ENDPOINT: '',
    }
  },

  mounted() {
    this.REST_ENDPOINT = getRestEndpoint();
  },
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ul {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    margin: 20px;
    width: 100%;

    .title {
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }

    .description {
      color: #666;
    }

    .links {
      color: #999;
    }
  }
}
</style>
