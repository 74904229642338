import exportFile from 'quasar/src/utils/export-file.js';;
import moment from 'moment';
import 'moment-timezone';

// const verDate = 08-12-2023;

//global
const SERVER_TIMEZONE_OFFSET = 3; // GMT+3

//john
// const REST_ENDPOINT = 'http://localhost:2000/api/'

//test
// const REST_ENDPOINT = 'https://testportal.altmedia.ro/api/'

//prod
const REST_ENDPOINT = 'https://sms.altmedia.ro/api/'

export function convertServerTimeToUserTime(serverTime, userOffset) {
  const serverTimeMoment = moment.tz(serverTime, `Etc/GMT${-SERVER_TIMEZONE_OFFSET}`);
  const userTimeMoment = serverTimeMoment.clone().utcOffset(userOffset * 60);
  return userTimeMoment.format('YYYY-MM-DD HH:mm');
}

export function convertUserTimeToUTC(userTime) {
  const userTimezoneOffset = sessionStorage.getItem('userTimezoneOffset');
  const userTimeMoment = moment(userTime, 'YYYY-MM-DD HH:mm').utcOffset(userTimezoneOffset * 60);
  return userTimeMoment.utc().format('YYYY-MM-DD HH:mm');
}

export function getRestEndpoint () {
  return REST_ENDPOINT;
}

export function wrapCsvValue (val, formatFn, row) {
    let formatted = formatFn !== void 0
      ? formatFn(val, row)
      : val

    formatted = formatted === void 0 || formatted === null
      ? ''
      : String(formatted)

    formatted = formatted.split('"').join('""')
    return `"${formatted}"`
}

export function exportTable (cols, rows, fileName, test) {
  let formattedDate = ''
  if (test) {
    formattedDate = test
  } else {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    formattedDate = `${year}.${month}.${day}`;
  }
  
  const content = [cols.map(col => wrapCsvValue(col.label))].concat(
    rows.map(row => cols.map(col => wrapCsvValue(
      typeof col.field === 'function'
        ? col.field(row)
        : row[ col.field === void 0 ? col.name : col.field ],
      col.format,
      row
    )).join(','))
  ).join('\r\n')
    return exportFile (
    fileName + formattedDate + '.csv',
    content,
    'text/csv'
  )
}

// GSM character set as per GSM 03.38
const GSM_7BIT_CHARS = "@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà";

export function isGsmCharacter(char) {
  return GSM_7BIT_CHARS.includes(char);
}

export function isGsmString(str) {
  return Array.from(str).every(isGsmCharacter);
}

export function calculateSmsLengthAndCount(text) {
  const maxGsmLength = 160;
  const maxUnicodeLength = 70;
  const extendedGsmLength = 153;
  const extendedUnicodeLength = 67;
  
  let length, segments;
  if (isGsmString(text)) {
      length = text.length;
      segments = length <= maxGsmLength ? 1 : Math.ceil(length / extendedGsmLength);
  } else {
      length = text.length;
      segments = length <= maxUnicodeLength ? 1 : Math.ceil(length / extendedUnicodeLength);
  }
  return `Characters count: ${length}, SMS number: ${segments}`;
}
