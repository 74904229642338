<template>
  <div class="password-page">
    <div class="password-container">
      <div class="image-container">
        <img src="../assets/altmedia-login.png" alt="">
      </div>
      <div class="form-container">
        <div class="form-text">
          <h2 class="form-title">
            <span>Welcome to Altmedia SMS Portal</span>
          </h2>
        </div>

        <!-- Activate Password Form -->
        <form v-if="mode === 'activate' && !successMessage" @submit.prevent="submitActivate">
          <div><span class="form-subtitle">Please change password after your first login</span></div>
          <div class="form-group">
            <label for="current-password">Current Password</label>
            <input v-model.trim="form.currentPassword" type="password" id="current-password" required>
            <span class="error">{{ formErrors.currentPassword }}</span>
          </div>
          <div class="form-group">
            <label for="new-password">New Password</label>
            <input v-model.trim="form.newPassword" type="password" id="new-password" required>
            <span class="error">{{ formErrors.newPassword }}</span>
          </div>
          <div class="form-group">
            <label for="confirm-password">Confirm Password</label>
            <input v-model.trim="form.confirmPassword" type="password" id="confirm-password" required>
            <span class="error">{{ formErrors.confirmPassword }}</span>
          </div>
          <div v-if="errorMessage" class="password-error">{{ errorMessage }}</div>
          <button type="submit" class="submitBtn">Change password</button>
        </form>

        <!-- Change Password Form -->
        <form v-if="mode === 'change' && !successMessage" @submit.prevent="submitChange">
          <div><span class="form-subtitle">Change password</span></div>
          <div class="form-group">
            <label for="current-password">Current Password</label>
            <input v-model.trim="form.currentPassword" type="password" id="current-password" required>
            <span class="error">{{ formErrors.currentPassword }}</span>
          </div>
          <div class="form-group">
            <label for="new-password">New Password</label>
            <input v-model.trim="form.newPassword" type="password" id="new-password" required>
            <span class="error">{{ formErrors.newPassword }}</span>
          </div>
          <div class="form-group">
            <label for="confirm-password">Confirm Password</label>
            <input v-model.trim="form.confirmPassword" type="password" id="confirm-password" required>
            <span class="error">{{ formErrors.confirmPassword }}</span>
          </div>
          <div v-if="errorMessage" class="password-error">{{ errorMessage }}</div>
          <button type="submit" class="submitBtn">Change Password</button>
        </form>

        <!-- Lost Password Form -->
        <form v-if="mode === 'lost' && !successMessage" @submit.prevent="submitLost">
          <div><span class="form-subtitle">Lost password</span></div>
          <div class="form-group">
            <label for="email">Email</label>
            <input v-model.trim="form.email" type="email" id="email" required>
            <span class="error">{{ formErrors.email }}</span>
          </div>
          <div v-if="displayRecaptcha" class="captchaBox">
            <div class="preview" ref="captchaPreview"></div>
            <input v-model.trim="userInput" @blur="checkCaptcha">
            <button class="captcha-refresh" @click="refreshCaptcha">Refresh</button>
            <p v-if="incorrectCaptcha" class="error-message">Incorrect CAPTCHA. Please try again.</p>
            <br><br>
          </div>
          <div v-if="errorMessage" class="email-error">{{ errorMessage }}</div>
          <button type="submit" class="submitBtn">Send</button>
        </form>

        <div v-if="mode === 'reset' && !successMessage">
          <span class="form-subtitle">Reset Password</span>
          <div v-if="errorMessage" class="password-error">{{ errorMessage }}</div>
        </div>
        
        <div v-if="successMessage" class="success-message" v-html="formattedSuccessMessage"></div>

        <br>
        <q-btn class="login" flat icon="navigate_before" label="Back to Login" :to="'/login/'" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { getRestEndpoint } from '@/utils/utils';
import { handleTokenExpiration } from '@/utils/auth';

export default {
  data() {
    return {
      mode: 'activate', 
      errorMessage: '',
      successMessage: '',
      displayRecaptcha: false,
      captchaCode: '',
      userInput: '',
      incorrectCaptcha: false,
      fonts: ["cursive", "sans-serif", "serif", "monospace"],
      form: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        email: ''
      },
      formErrors: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        email: ''
      }
    };
  },
  methods: {
    submitActivate() {
      this.clearErrors();

      if (!this.form.currentPassword) {
        this.formErrors.currentPassword = "Current password cannot be empty!";
      }

      const passwordCriteria = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,20}$/;

      if (!passwordCriteria.test(this.form.newPassword)) {
        this.formErrors.newPassword = "New password must be 8-20 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character.";
      }

      if (this.form.newPassword !== this.form.confirmPassword) {
        this.formErrors.confirmPassword = "Passwords do not match!";
      }

      if (Object.values(this.formErrors).some(error => error)) {
        return;
      }

      this.validatePassword();
    },
    submitChange() {
      this.clearErrors();

      if (!this.form.currentPassword) {
        this.formErrors.currentPassword = "Current password cannot be empty!";
      }

      const passwordCriteria = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,20}$/;

      if (!passwordCriteria.test(this.form.newPassword)) {
        this.formErrors.newPassword = "New password must be 8-20 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character.";
      }

      if (this.form.newPassword !== this.form.confirmPassword) {
        this.formErrors.confirmPassword = "Passwords do not match!";
      }

      if (Object.values(this.formErrors).some(error => error)) {
        return;
      }

      this.validatePassword('change');
    },
    submitLost() {
      this.clearErrors();

      if (!this.form.email) {
        this.formErrors.email = "Email cannot be empty!";
      }

      if (Object.values(this.formErrors).some(error => error)) {
        return;
      }

      this.sendLostEmail();
    },
    async sendLostEmail() {
      try {
        const response = await axios.post(`${this.REST_ENDPOINT}password/lost`, {
          email: this.form.email
        }, { withCredentials: true });

        this.successMessage = response.data.message;

        this.clearForm();
      } catch (error) {
        if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
          handleTokenExpiration(this.$router);
        } else {
          this.errorMessage = "An error occurred while sending the email.";
        }
      }
    },
    async resetPassword() {
      try {
        const response = await axios.post(`${this.REST_ENDPOINT}password/reset`, { token: this.token });

        if (response.data.valid) {
          this.successMessage = response.data.message;
        } else {
          this.errorMessage = response.data.message;
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = "An error occurred while confirming the password reset.";
        }
      }
    },
    async validatePassword() {
      try {
        const response = await axios.post(`${this.REST_ENDPOINT}password/activate`, {
          currentPassword: this.form.currentPassword,
          newPassword: this.form.newPassword
        }, { withCredentials: true });

        this.successMessage = response.data.message; 

        this.clearForm();
      } catch (error) {
        if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
          handleTokenExpiration(this.$router);
        } else {
          this.errorMessage = "An error occurred while validating the password.";
        }
      }
    },
    async checkShowCaptcha() {
        try {
          const response = await axios.get(`${this.REST_ENDPOINT}password/check-captcha`);
          this.displayRecaptcha = response.data.displayRecaptcha;
          
          if (this.displayRecaptcha) {
            this.generateCaptcha();
          }
        } catch (error) {
          console.error('Error checking captcha:', error);
        }
      },

      generateCaptcha() {
        let value = Math.random().toString(36).substr(2, 5).toUpperCase();
        this.captchaCode = value;
      },
      
      setCaptcha() {
        const captchaPreview = this.$refs.captchaPreview;
        if (captchaPreview) {
          const html = this.captchaCode.split("").map(char => {
            const rotate = -40 + Math.trunc(Math.random() * 80);
            const fontSize = 18 + Math.trunc(Math.random() * 6);
            const letterSpacing = Math.trunc(Math.random() * 4);
            const font = Math.trunc(Math.random() * this.fonts.length);
            return `<span style="display: inline-block; transform: rotate(${rotate}deg); font-family: ${this.fonts[font]}; font-size: ${fontSize}px; margin-right: ${letterSpacing}px;">${char}</span>`;
          }).join("");
          captchaPreview.innerHTML = html;
        }
      },

      refreshCaptcha() {
        this.generateCaptcha();
        this.setCaptcha();
        this.userInput = '';
        this.incorrectCaptcha = false;
      },

      checkCaptcha() {
        if (this.userInput.toUpperCase() === this.captchaCode) {
          this.incorrectCaptcha = false;
          this.refreshCaptcha();
        } else {
          this.incorrectCaptcha = true;
        }
      },
    clearErrors() {
      this.errorMessage = '';
      this.formErrors = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      };
    },
    clearForm() {
      this.form = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      };
    },
    setModeFromQuery() {
      this.mode = this.$route.query.mode || 'activate';
    },
  },
  created() {
    this.setModeFromQuery();
  },
  mounted() {
    this.REST_ENDPOINT = getRestEndpoint();
    this.checkShowCaptcha();
    if (this.mode === 'reset' && this.token) {
      this.resetPassword();
    }
  },
  updated() {
    if (this.displayRecaptcha) {
      this.$nextTick(() => {
        this.setCaptcha();
      });
    }
  },
  watch: {
    '$route.query.mode': 'setModeFromQuery'
  },
  computed: {
    formattedSuccessMessage() {
      if (Array.isArray(this.successMessage)) {
        return this.successMessage.join('<br>');
      }
      return this.successMessage;
    },
    token() {
      return this.$route.query.token;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/styles.scss';

.password-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;

    .image-container {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      height: 450px;
      img {
        width: 600px;
        height: 450px;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .form-container {
      flex: 1;
      background-color: #fff;
      width: 500px;
      height: 450px;

      .form-text {
        display: flex;
        flex-direction: column;
        .form-title {
          font-size: 1.5rem;
          line-height: 2rem;
          font-weight: bold;
          margin: 20px auto;
          color: #5e5873;
        }
      }

      .form-subtitle {
        display: block;
        margin: 20px auto 40px;
        font-weight: bold;
        font-size: 1.1rem;
        color: #008dd2;
        margin-bottom: 1rem;
      }

      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        label {
          font-weight: bold;
          margin-bottom: 0.5rem;
          color: #5e5873;
          font-size: .857rem;
        }

        input {
          padding: 0.5rem;
          border: 1px solid $altmedia-base-color;
          border-radius: 5px;
          font-size: 1rem;
        }

        .error {
          color: red;
          font-size: 0.8rem;
          margin-top: 0.5rem;
        }
      }

      .submitBtn {
        width: 100%;  
      }

      .password-error {
        width: 100%;
        display: block;
        margin-top: 20px;
        font-size: 1rem;
        color: red;
      }

      .success-message {
        width: 100%;
        display: block;
        margin: 20px;
        font-size: 1rem;
        color: green;
      }
    }
  }
}
</style>
