
<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Pricing" icon="price_change" to="/pricing" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>
  
  <div class="q-pa-md">
    <q-table
      title = "Reports EDR"
      :rows = "smsPricingItems"
      :columns = "columns"
      row-key = "id"
      :loading = "loading"
      :pagination="{ rowsPerPage: 20 }"
      color = "primary"
      :filter="filter"
    >

      <template v-slot:top-left>
        <q-btn
          color="altmedia-blue"
          icon="archive"
          label="Export"
          no-caps
          @click="exportTableCsv"
        />
      </template>

      <template v-slot:top-right>
        <q-space />
        <q-input dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      
      </q-table>
    </div>
  </template>
    
  <script>
    import axios from "axios";
    import { getRestEndpoint, exportTable } from '@/utils/utils'
    import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
  
    export default {
      name: 'PricingList',
      data() {
        return {
          loading: false,
          filter: '',
          selected: [],
          smsPricingItems: [],
          REST_ENDPOINT: '',
          columns: [
            {
              name: 'mccmnc',
              required: true,
              label: 'MCCMNC',
              align: 'left',
              style: 'width: 100px',
              field: row => row.mccmnc,
              format: val => `${val}`,
              sortable: true
            },
            { name: 'Country', align: 'left', label: 'Country', field: 'country', sortable: true, style: 'max-width: 150px'},
            { name: 'Network', align: 'left', label: 'Network', field: 'network', sortable: true },
            { name: 'Rate', align: 'center', label: 'Rate', field: 'rate', sortable: true, },
          ]
        }
      },
      created() {
        handleClientTokenExpiration(this.$router);
      },
      methods: {
        readDataFromAPI() {
          this.loading = true;
          axios
            .get(`${this.REST_ENDPOINT}reports/pricing`, { withCredentials: true })
            .then((response) => {
              this.loading = false;
              this.smsPricingItems = response.data;
            })
            .catch((error) => {
              if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
                handleTokenExpiration(this.$router);
              }
            });
        },

        exportTableCsv() {
          exportTable(this.columns, this.smsPricingItems, 'pricing_')
        } 
        
      },
      mounted() {
        this.REST_ENDPOINT = getRestEndpoint();
        this.readDataFromAPI();
      },
      
    }
  </script>
    