<template>

  <q-dialog v-model="confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="delete" color="negative" text-color="white" />
        <span class="q-ml-sm">Are you sure?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="negative" v-close-popup />
        <q-btn flat label="Delete" color="positive" v-close-popup @click="removeSelectedItems" />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Billing" icon="receipt_long"  />
      <q-breadcrumbs-el label="Payments" icon="local_atm" to="/payments" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>

  <div class="q-pa-md">
    <q-table
      title = "Payments"
      :rows = "paymentsItems"
      :columns = "columns"
      row-key = "id"
      :sort-by="'id'"
      :loading = "loading"
      :pagination="{ rowsPerPage: 20 }"
      color = "primary"
      :filter="filter"
    >

      <template v-slot:body-cell-actions="scope">
        <q-td :props="scope">
          <q-btn flat round icon="delete" color="negative" @click=removeItem(scope.row.id)>
            <q-tooltip class="bg-negative">Delete</q-tooltip></q-btn>
        </q-td>
      </template>

      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>

      <template v-slot:top-left>
        <q-btn
          color="altmedia-blue"
          icon="archive"
          label="Export"
          no-caps
          @click="exportTableCsv"
        />
        <q-space />
      </template>

      <template v-slot:top-right>
        <q-input  dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>

      </q-table>
  </div>
</template>
  
<script>
  import { getRestEndpoint, exportTable } from '@/utils/utils'
  import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
  import axios from "axios";
  import { ref } from 'vue'
  
  export default {
    name: 'PaymentsList',
    data() {
      return {
        loading: false,
        filter: '',
        selected: [],
        REST_ENDPOINT: '',
        paymentsItems: [],
        columns: [
          { name: 'payment amount', align: 'left', label: 'Payment amount', field: 'pay_amount', sortable: true },
          { name: 'payment_currency', align: 'left', label: 'Payment currency', field: 'pay_currency_code', sortable: true },
          { name: 'payment_ref_code', align: 'left', label: 'Payment ref code', field: 'pay_ref_code', sortable: true },
          { name: 'payment_date', align: 'left', label: 'Payment date', field: 'pay_date', sortable: true },
        ]
      }
    },
    created() {
      handleClientTokenExpiration(this.$router);
    },
    methods: {
      exportTableCsv() {
        exportTable(this.columns, this.paymentsItems, 'payments_')
      },
      readDataFromAPI() {
        this.loading = true;
        axios
          .get(`${this.REST_ENDPOINT}billing/payments`, { withCredentials: true })
          .then((response) => {
            this.loading = false;
            this.paymentsItems = response.data.sort((a, b) => b.id - a.id);
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            } else {
              this.loading = false;
            }
          });
      },
      
      
    },
    mounted() {
      this.REST_ENDPOINT = getRestEndpoint();
      this.readDataFromAPI();
    },
    setup () {
      return {
        confirm: ref(false),
      }
    }
  }
</script>
