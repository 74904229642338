<template>

  <q-dialog v-model="confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="delete" color="negative" text-color="white" />
        <span class="q-ml-sm">Are you sure?</span>
      </q-card-section>

    </q-card>
  </q-dialog>

  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Billing" icon="receipt_long"  />
      <q-breadcrumbs-el label="Invoices" icon="calculate" to="/invoices" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>

  <div class="q-pa-md">
    <q-table
      title = "Invoices"
      :rows = "invoicesItems"
      :columns = "columns"
      row-key = "id"
      :sort-by="'id'"
      :loading = "loading"
      :pagination="{ rowsPerPage: 20 }"
      color = "primary"
      :filter="filter"
    >

      <template v-slot:body-cell-actions="scope">
        <q-td :props="scope">
          
          <q-btn flat round icon="download" color="positive" @click=exportInvoice(scope.row.id)>
            <q-tooltip class="bg-positive">Export invoice</q-tooltip>
          </q-btn>
        </q-td>
        <q-space /><br >
      </template>

      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>

      <template v-slot:top-left>
        <q-btn
          color="altmedia-blue"
          icon="archive"
          label="Export"
          no-caps
          @click="exportTableCsv"
        />
        <q-space />
      </template>

      <template v-slot:top-right>
        <q-input  dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      
      </q-table>
  </div>
</template>
  
<script>
  import { getRestEndpoint, exportTable } from '@/utils/utils'
  import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
  import axios from "axios";
  import { ref } from 'vue'
  
  export default {
    name: 'InvoicesList',
    data() {
      return {
        loading: false,
        filter: '',
        selected: [],
        REST_ENDPOINT: '',
        invoicesItems: [],
        columns: [
          { name: 'inv_start_date', align: 'left', label: 'Start Date', field: 'inv_start_date', sortable: true },
          { name: 'inv_end_date', align: 'left', label: 'End Date', field: 'inv_end_date', sortable: true },
          { name: 'inv_issue_date', align: 'left', label: 'Issue Date', field: 'inv_issue_date', sortable: true },
          { name: 'inv_estimated_amount', align: 'left', label: 'Estimated amount', field: 'inv_estimated_amount', sortable: true },
          { name: 'currency_code', align: 'left', label: 'Currency', field: 'currency_code', sortable: true },
          { name: 'actions', align: 'center', label: 'Actions', sortable: false, style: 'width: 150px' },
        ]
      }
    },
    created() {
      handleClientTokenExpiration(this.$router);
    },
    methods: {
      exportInvoice(invoiceId) {
        axios
          .get(`${this.REST_ENDPOINT}billing/invoices/exp/` + invoiceId, {
            withCredentials: true,
            responseType: 'blob'
          })
          .then(response => {
            const xFilenameHeader = response.headers['x-filename'] || response.headers['X-Filename'];

            if (xFilenameHeader) {
              let filename = xFilenameHeader;

              const contentDisposition = response.headers['content-disposition'];
              if (contentDisposition) {
                const match = contentDisposition.match(/filename=([^;]+)/);
                filename = match ? match[1] : filename;
              }

              const blob = new Blob([response.data], { type: 'application/pdf' });
              const link = document.createElement('a');

              if (window.navigator.msSaveBlob) {
                // For IE
                window.navigator.msSaveBlob(blob, filename);
              } else {
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
              }
            } else {
              console.error('X-Filename header not found in the response.');
            }
          })
          .catch(error => {
            console.error('Error exporting invoice:', error);
          });
      },      
      exportTableCsv() {
        const columnsWithoutActions = [...this.columns];
        columnsWithoutActions.pop();
        exportTable(columnsWithoutActions, this.invoicesItems, 'invoices_')
      },
      readDataFromAPI() {
        this.loading = true;
        axios
          .get(`${this.REST_ENDPOINT}billing/invoices`, { withCredentials: true })
          .then((response) => {
            this.loading = false;
            this.invoicesItems = response.data.sort((a, b) => b.id - a.id);
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            } else {
              this.loading = false;
            }
          });
      },
    },
    mounted() {
      this.REST_ENDPOINT = getRestEndpoint();
      this.readDataFromAPI();
    },
    setup () {
      return {
        confirm: ref(false),
      }
    }
  }
</script>
