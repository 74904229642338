<template>
  <div class="login-page">


    <div class="login-container">

      <div class="image-container">
        <img src="../assets/altmedia-login.png" alt="">
      </div>

      <div class="form-container">

        <div class="form-text">
          <h2 class="form-title">
            <span>Welcome to Altmedia SMS Portal</span>
          </h2>
          <span class="form-subtitle">Please sign-in to your account</span>
        </div>
        
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="username">Username *</label>
            <input v-model.trim="form.usernamePortal" type="text" id="username"  />
            <span class="error">{{ formErrors.usernamePortal }}</span>
          </div>
          <div class="form-group">
            <label for="password">Password *</label>
            <input v-model.trim="form.password" type="password" id="password"  />
            <span class="error">{{ formErrors.password }}</span>
          </div>
          <button class="submitBtn" type="submit">Login</button>
          <span class="login-error">{{ formErrors.login }}</span>
          <div class="form-group register">
            <span>New on our platform? <router-link to="/register">Register</router-link></span>
          </div>

          <div class="form-group register">
            <span><router-link :to="{ path: '/password', query: { mode: 'lost' } }">Lost Password</router-link></span>
          </div>


        </form>
      </div>

    </div>

  </div>
</template>

<script>
  import store from '../store'
  import axios from 'axios'
  import { loginUser } from '../utils/auth'
  import { getRestEndpoint } from '../utils/utils'
  import { handleTokenExpiration } from '@/utils/auth';

  export default {
    name: 'login-user',
    data () {
      return {
        REST_ENDPOINT: '',
        
        form: {
          usernamePortal: '',
          password: ''
        },
        formErrors: {
          usernamePortal: '',
          password: '',
          login: ''
        },
      }
    },
    created() {
      const message = this.$route.query.message;
      if (message) {
        this.formErrors.login = decodeURIComponent(message);
      }
    },
    methods: {
      login() {
        axios.post(`${this.REST_ENDPOINT}login`, {
          usernamePortal: this.form.usernamePortal,
          password: this.form.password
        }, { withCredentials: true })
        .then(response => {
          if (response.status === 200) {
            loginUser(response.data.token, this.form.usernamePortal);
            store.commit('setUsername', this.form.usernamePortal);
            store.commit('toggleLogin', true);

            const userTimezoneOffset = parseInt(response.data.timezone.replace('UTC', ''), 10);
            sessionStorage.setItem('userTimezoneOffset', userTimezoneOffset);

            if (response.data.first_login === 1) {
              this.$router.push('/password?activate');
            } else {
              this.fetchProductIds().then(() => {
                this.$router.push('/');
              });
            }
          }
        })
        .catch(error => {
          if (error.response) {
            this.formErrors.login = error.response.data.error;
          } else if (error.request) {
            this.formErrors.login = 'Maintenance mode. Please try again later.';
          } else {
            this.formErrors.login = 'An unexpected error occurred. Please try again later.';
          }
        });
      },

      async fetchProductIds() {
        try {
          const response = await axios.get(`${this.REST_ENDPOINT}reports/product`, { withCredentials: true });
          const productIds = response.data.productList.map(product => ({ id: product.id, descr: product.descr }));
          const selProductId = response.data.selProductId;
          let selProductDescr = '';
          
          const selectedProduct = productIds.find(product => product.id === selProductId);
          if (selectedProduct) {
            selProductDescr = selectedProduct.descr;
          }

          localStorage.setItem('productIds', JSON.stringify(productIds));
          localStorage.setItem('selProductId', selProductId);
          localStorage.setItem('selProductDescr', selProductDescr);
        } catch (error) {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        }
      },
      submitForm() {
        this.validateForm()
        if (this.isFormValid()) {
          this.login();
        }
      },
      validateForm() {
        this.formErrors.login = ''
        if (!this.form.usernamePortal) {
          this.formErrors.usernamePortal = 'Username field is required'
        } else {
          this.formErrors.usernamePortal = ''
        }
        if (!this.form.password) {
          this.formErrors.password = 'Password field is required'
        } else {
          this.formErrors.password = ''
        }
      },
      isFormValid() {
        return Object.values(this.formErrors).every((error) => !error)
      },    
    },
    mounted() {
      this.REST_ENDPOINT = getRestEndpoint();
    }
  }
</script>

<style lang="scss">
@import '../styles/styles.scss';

  .login-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 500px;
      background-color: #fff;
      padding: 30px;
      border-radius: 5px;

      .image-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        height: 450px;
        img {
          width: 600px;
          height: 450px;
          object-fit: cover;
          border-radius: 5px;
        }
      }

      .form-container {
        flex: 1;
        background-color: #fff;
        width: 500px;
        height: 450px;

        .form-text {
          display: flex;
          flex-direction: column;
          .form-title {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: bold;
            margin: 20px auto;
            color: #5e5873;
            
          }
          .form-subtitle {
            margin: 0 auto 40px;
            font-size: 1rem;
            color: #5e5873;
            margin-bottom: 1rem;
          }
        }

        .form-group {
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;

          label {
            font-weight: bold;
            margin-bottom: 0.5rem; 
            color: #5e5873;
            font-size: .857rem;
          }

          input {
            padding: 0.5rem;
            border: 1px solid $altmedia-base-color;
            border-radius: 5px;
            font-size: 1rem;
          }

          .error {
            color: red;
            font-size: 0.8rem;
            margin-top: 0.5rem;
          }
        }

        .register {
          font-size: 1rem;
          margin-top: 1rem;
          a {
            color: $altmedia-base-color;
          }
        }

        .submitBtn {
          width: 100%;  
        }

        .login-error {
          width: 100%;
          display: block;
          margin-top: 20px;
          font-size: 1rem;
          color: red;
        }
      }
    }
  }
</style>