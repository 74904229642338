<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Blacklist" icon="cancel" to="/blacklist" />
      <q-breadcrumbs-el label="Add Blacklist" icon="add" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>
  
  <q-card class="quick-send-card">
    <q-form @submit.prevent="submitForm" class="qForm">
      <q-card-section>
        <div class="text-h6">Add Blacklist</div>
      </q-card-section>

      <q-separator inset />

      <q-card-section class="column q-gutter-md">

        <q-input type="textarea" v-model.trim="form.blackListContent" label="Paste your blacklist numbers *" />
        <span class="error-message">{{ formErrors.blackListContent }}</span>

        <q-btn-toggle
          v-model="form.separator"
          label="Separator"
          toggle-color="altmedia-blue"
          :options="[
            {label: ', (Comma)', value: ','},
            {label: '; (Semicolon)', value: ';'},
            {label: '| (Bar)', value: '|'},
            {label: '| (Tab)', value: '\t'},
            {label: '| (New line)', value: '\n'}
          ]"
        />
        <span class="error-message">{{ formErrors.separator }}</span>        
      </q-card-section>

      <q-card-actions>
        <q-btn icon="add" color="altmedia-blue" label="Add Blacklist" @click="submitForm()"></q-btn>
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script>
import { getRestEndpoint } from '@/utils/utils'
import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
import axios from "axios";

export default {
  name: 'SmsBlackListAdd',

  data() {
    return {
      blackListItems: [],
      REST_ENDPOINT: '',
      options:[
            {label: ', (Comma)', value: ','},
            {label: '; (Semicolon)', value: ';'},
            {label: '| (Bar)', value: '|'},
            {label: '| (Tab)', value: '\t'},
            {label: '| (New line)', value: '\n'}
          ],
      form: {
        blackListContent: '',
        separator: null,
      },
      formErrors: {
        blackListContent: '',
        separator: '',
      }
    }
  },
  created() {
    handleClientTokenExpiration(this.$router);
  },
  methods: {
    async submitForm() {
      this.validateForm();
      if (this.isFormValid()) {
        const requests = this.blackListItems.map((item) =>
          axios.post(`${this.REST_ENDPOINT}blacklist`, { bnumber: item }, { withCredentials: true })
        );
        try {
          await axios.all(requests);
          this.$router.push('/blacklist');
        } catch (error) {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          } else if (error.response && error.response.status === 400) {
            this.formErrors.blackListContent =
              'Error: duplicate content, numbers already exist in the blacklist. Duplicate numbers will be ignored.';
          }
        }
      }
    },

    validateForm() {
      if (!this.form.blackListContent) {
        this.formErrors.blackListContent = 'Blacklist numbers field is required'
      } else {
        this.formErrors.blackListContent = ''
      }

      if (!this.form.separator) {
        this.formErrors.separator = 'Select separator'
        return false;
      } else {
        this.formErrors.separator = ''
      }

      this.blackListItems = this.form.blackListContent.split(this.form.separator);

      for (let i = 0; i < this.blackListItems.length; i++) {
        const item = this.blackListItems[i];
        const selectedOption = this.options.find(option => option.value === this.form.separator);
        if (isNaN(item)) {
          this.formErrors.separator = 'Blacklist numbers field is not valid. It must contain only numbers separated by ' + selectedOption.label + '.'
        } else {
        this.formErrors.separator = ''
        }
      }
    },
    isFormValid() {
      return Object.values(this.formErrors).every((error) => !error)
    },
  },
  mounted() {
    this.REST_ENDPOINT = getRestEndpoint();
  },
  
}
</script>
  
<style lang="scss">

</style>