<template>
  <div class="home">

      <div class="dash">

        <div class="dash-row">
          <q-card class="q-pa-md dash-card">
            <div class="text">
              <q-card-section>
                <div class="text-h4">{{ total_sms }}</div>
              </q-card-section>
              <q-card-section>
                <div class="text-h6">Total SMS delivered today</div>
              </q-card-section>
            </div>
            <div class="icon">
              <q-icon name="sms" />
            </div>
          </q-card>

          <q-card class="q-pa-md dash-card">
            <div class="text">
              <q-card-section>
                <div class="text-h4">{{ total_templates }}</div>
              </q-card-section>
              <q-card-section>
                <div class="text-h6">Total Templates</div>
              </q-card-section>
            </div>
            <div class="icon">
              <q-icon name="view_timeline" />
            </div>
          </q-card>
        </div>

        <div class="dash-row">
          <q-card class="q-pa-md dash-card">
            <div class="text">
              <q-card-section>
                <div class="text-h4">{{ total_contacts }}</div>
              </q-card-section>
              <q-card-section>
                <div class="text-h6">Total Contacts</div>
              </q-card-section>
            </div>
            <div class="icon">
              <q-icon name="account_circle" />
            </div>
          </q-card>

          <q-card class="q-pa-md dash-card">
            <div class="text">
              <q-card-section>
                <div class="text-h4">{{ total_blacklist }}</div>
              </q-card-section>
              <q-card-section>
                <div class="text-h6">Total Blacklist</div>
              </q-card-section>
            </div>
            <div class="icon">
              <q-icon name="cancel" />
            </div>
          </q-card>
        </div>
        <div class="dash-row">
          <q-card class="q-pa-md dash-card">
            <div class="text">
              <q-card-section>
                <div class="text-h4">{{ total_contact_groups }}</div>
              </q-card-section>
              <q-card-section>
                <div class="text-h6">Total Contact Groups</div>
              </q-card-section>
            </div>
            <div class="icon">
              <q-icon name="groups" />
            </div>
          </q-card>

          <q-card class="q-pa-md dash-card">
            <div class="text">
              <q-card-section>
                <div class="text-h4">{{ total_enabled_campaigns }}</div>
              </q-card-section>
              <q-card-section>
                <div class="text-h6">Total Enabled Campaigns</div>
              </q-card-section>
            </div>
            <div class="icon">
              <q-icon name="campaign" />
            </div>
          </q-card>
        </div>
      </div>    
  </div>
</template>

<script>
import { getRestEndpoint } from '@/utils/utils'
import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
import axios from "axios";

export default {
  name: 'DashBoard',
  data() {
    return {
      total_sms: 0,
      total_templates: 0,
      total_contacts: 0,
      total_blacklist: 0,
      total_contact_groups: 0,
      total_enabled_campaigns: 0,
      startDate: '',
      endDate: '',
    }
  },
  created() {
    handleClientTokenExpiration(this.$router);
  },
  methods: {
    getTotalSMS() {
      axios
        .post(`${this.REST_ENDPOINT}reports/sms-usage-stats`, {
          startDate: this.startDate,
          endDate: this.endDate
        }, { withCredentials: true })
        .then(response => {
          this.total_sms = response.data[response.data.length-1]['count_delivrd'];
        })
        .catch(error => {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },
    getTotalContacts() {
      axios.get(`${this.REST_ENDPOINT}contact`, { withCredentials: true })
        .then((response) => {
          this.total_contacts = response.data.numContacts;
        })
        .catch(error => {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },
    getTotalTemplates() {
      axios.get(`${this.REST_ENDPOINT}sms-template`, { withCredentials: true })
        .then((response) => {
          this.total_templates = response.data.length;
        })
        .catch(error => {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },
    getTotalBlacklist() {
      axios.get(`${this.REST_ENDPOINT}blacklist`, { withCredentials: true })
        .then((response) => {
          this.total_blacklist = response.data.length;
        })
        .catch(error => {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },
    getTotalContactGroups() {
      axios.get(`${this.REST_ENDPOINT}contact-groups/total`, { withCredentials: true })
        .then((response) => {
          this.total_contact_groups = response.data.total_count;
        })
        .catch(error => {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },
    getTotalEnabledCampaigns() {
      axios.get(`${this.REST_ENDPOINT}campaign/enabled`, { withCredentials: true })
        .then((response) => {
          this.total_enabled_campaigns = response.data.total_count;
        })
        .catch(error => {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },
    getAllStats() {
      this.getTotalSMS();
      this.getTotalContacts();
      this.getTotalTemplates();
      this.getTotalBlacklist();
      this.getTotalContactGroups();
      this.getTotalEnabledCampaigns();
    }
  },
  mounted() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    this.startDate = `${year}.${month}.${day}`;
    this.endDate = `${year}.${month}.${day}`;
    this.REST_ENDPOINT = getRestEndpoint();
    this.getAllStats();
  },
  watch: {
    '$route.query'(newQuery, oldQuery) {
      if (newQuery.productChanged && newQuery.productId !== oldQuery.productId) {
        this.getAllStats();
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .home {

    display: flex;
    align-items: center;
    justify-content: center;

    .dash {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 150px auto;
      max-width: 780px;
      padding: 0 20px;

      .dash-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .dash-card {
          display: flex;
          align-items: center;
          width: 350px;
          margin-bottom: 40px;
          height: 115px;
          box-shadow: 0 0 2px #008dd2;
          color: rgb(110, 107, 123);
          font-family: Montserrat, Helvetica, Arial, serif;

          .q-card__section {
            padding: 10px;
          }

          .text {
            width: 300px;
            .text-h4 {
              font-size: 1.2rem;
              font-weight: 600;
            }
            .text-h6 {
              font-size: 1rem;
            }
          }

          .icon {
            widows: 50px;
            font-size: 2.5rem;
            color: #008dd2;
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .dash {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;

        .dash-row {
          flex-direction: column;

          .dash-card {
            margin-bottom: 20px;
          }
        }
      }
    }

  }


</style>
