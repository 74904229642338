import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import { isLoggedIn } from '../utils/auth'
import DashBoard from '../views/DashBoard.vue'
import BlackList from '../views/BlackList.vue'
import BlackListAdd from '../views/BlackListAdd.vue'
import Campaigns from '../views/Campaigns.vue'
import CampaignDetails from '../views/CampaignDetails.vue'
import ReportsEdr from '../views/ReportsEdr.vue'
import ReportsSmsUsageStats from '../views/ReportsSmsUsageStats.vue'
import ContactsGroups from '../views/ContactGroups.vue'
import ContactGroupDetails from '../views/ContactGroupDetails.vue'
import ContactsGroupAdd from '../views/ContactGroupAdd.vue'
import ContactsAdd from '../views/ContactsAdd.vue'
import ContactGroupEdit from '@/views/ContactGroupEdit.vue'
import ContactsImport from '../views/ContactsImport.vue'
import ContactsEdit from '../views/ContactsEdit.vue'
import SmsTemplates from '../views/SmsTemplates.vue'
import SmsTemplatesAdd from '@/views/SmsTemplatesAdd.vue'
import SmsTemplatesEdit from '@/views/SmsTemplatesEdit.vue'
import SmsTemplatesDuplicate from '@/views/SmsTemplatesDuplicate.vue'
import SmsCampaignBuilder from '../views/SmsCampaignBuilder.vue'
import SmsQuickSend from '../views/SmsQuickSend.vue'
import VerifyEmail from '../pages/VerifyEmail.vue'
import LoginUser from '../pages/LoginUser.vue'
import RegisterUser from '../pages/RegisterUser.vue'
import PasswordUser from '../pages/PasswordUser.vue'
import PricingList from '@/views/PricingList.vue'
import Invoices from '@/views/Invoices.vue'
import Payments from '@/views/Payments.vue'
import Sender from '@/views/Sender.vue'
import SenderAdd from '@/views/SenderAdd.vue'
import Docs from '@/views/Docs.vue'
import ApiKey from '@/views/ApiKey.vue'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashBoard
  },
  {
    path: '/verify',
    name: 'verify-email',
    component: VerifyEmail,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/login',
    name: 'login-user',
    component: LoginUser,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/register',
    name: 'register-user',
    component: RegisterUser,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/password',
    name: 'password-user',
    component: PasswordUser,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/pricing',
    name: 'pricing-list',
    component: PricingList
  },
  {
    path: '/docs',
    name: 'docs',
    component: Docs
  },
  {
    path: '/apikey',
    name: 'apikey',
    component: ApiKey
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments
  },
  {
    path: '/sender',
    name: 'Sender',
    component: Sender
  },
  {
    path: '/sender-add',
    name: 'sender-add',
    component: SenderAdd
  },
  {
    path: '/blacklist',
    name: 'Blacklist',
    component: BlackList
  },
  {
    path: '/blacklist-add',
    name: 'blacklist-add',
    component: BlackListAdd
  },
  {
    path: '/reports-campaigns/campaign/:id?',
    name: 'CampaignDetails',
    component: CampaignDetails
  },
  {
    path: '/reports-campaigns',
    name: 'AllCampaigns',
    component: Campaigns
  },
  {
    path: '/reports-campaigns/contact-group/:contactGroupId',
    name: 'GroupCampaigns',
    component: Campaigns
  },
  {
    path: '/reports-edr',
    name: 'reports-edr',
    component: ReportsEdr
  },
  {
    path: '/reports-sms-usage-stats',
    name: 'reports-sms-usage-stats',
    component: ReportsSmsUsageStats
  },
  {
    path: '/contact-groups',
    name: 'contact-groups',
    component: ContactsGroups
  },
  {
    path: '/contact-group-add',
    name: 'contact-group-add',
    component: ContactsGroupAdd
  },
  {
    path: '/contact-group-edit/:id',
    name: 'ContactGroupEdit',
    component: ContactGroupEdit
  },
  {
    path: '/contact-group/:id',
    name: 'ContactGroupDetails',
    component: ContactGroupDetails
  },
  {
    path: '/contacts-add/:id',
    name: 'contacts-add',
    component: ContactsAdd
  },
  {
    path: '/contacts-import/:id',
    name: 'contacts-import',
    component: ContactsImport
  },
  {
    path: '/contacts-edit/:id',
    name: 'contacts-edit',
    component: ContactsEdit
  },
  {
    path: '/templates-sms',
    name: 'templates-sms',
    component: SmsTemplates
  },
  {
    path: '/templates-sms-add',
    name: 'templates-sms-add',
    component: SmsTemplatesAdd
  },
  {
    path: '/templates-sms-edit/:id',
    name: 'templates-sms-edit',
    component: SmsTemplatesEdit
  },
  {
    path: '/templates-sms-duplicate/:id',
    name: 'templates-sms-duplicate',
    component: SmsTemplatesDuplicate
  },
  {
    path: '/sms-campaign-builder',
    name: 'sms-campaign-builder',
    component: SmsCampaignBuilder
  },
  {
    path: '/sms-quick-send',
    name: 'sms-quick-send',
    component: SmsQuickSend
  }  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {

    if (to.path.startsWith('/verify')) {
      next();
    } else {
      if (!isLoggedIn() && to.name !== 'login-user' && to.name !== 'register-user' && to.name !== 'password-user') {
        next({ name: 'login-user' });
      } else {
        next();
      }
    }

    if ((to.path === '/reports-edr') || (to.path === '/reports-sms-usage-stats') || (to.path === '/reports-campaigns') || to.path.startsWith('/reports-campaigns/campaign/')) {
      store.commit('toggleExpansionReports', true);
    } else {
      store.commit('toggleExpansionReports', false);
    }

    if ((to.path === '/invoices') || (to.path === '/payments')) {
      store.commit('toggleExpansionBilling', true);
    } else {
      store.commit('toggleExpansionBilling', false);
    }

    if ((to.path === '/templates-sms') || (to.path === '/templates-sms-add') || to.path.startsWith('/templates-sms-edit/') || to.path.startsWith('/templates-sms-duplicate/')) {
      store.commit('toggleExpansionTemplates', true);
    } else {
      store.commit('toggleExpansionTemplates', false);
    }

    if ((to.path === '/sms-campaign-builder') || (to.path === '/sms-quick-send')) {
      store.commit('toggleExpansionSms', true);
    } else {
      store.commit('toggleExpansionSms', false);
    }
  }
);

export default router