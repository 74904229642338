<template>
  <div class="register-page">

    <div class="register-container">

      <div class="image-container">
        <img src="../assets/altmedia-register.png" alt="">
      </div>

      <div class="form-container">
        
        <div class="q-pa-md">

          <q-stepper
            v-model="step"
            header-nav
            ref="stepper"
            color="primary"
            animated
          >
            <q-step
              :name="1"
              title="Account"
              caption="Enter credentials"
              
              :done="step > 1"
              :header-nav="step > 1"
              icon="home"
            >
              <div class="step-content">
                
                <q-form @submit.prevent="submitForm" class="qForm">
                  <q-card-section>
                    <div class="text-h6">Account Information</div>
                    <div class="text-h7">Fill the below form to create a new account</div>
                  </q-card-section>

                  <q-separator inset />

                  <q-card-section class="column q-gutter-md">

                    <q-input v-model.trim="form.email" label="Email *" />
                    <span class="error-message">{{ formErrors.email }}</span>

                    <q-input v-model.trim="form.email2" label="Confirm Email *" />
                    <span class="error-message">{{ formErrors.email2 }}</span>
                    
                    <q-select
                      :options="optionsTimezone"
                      label="Timezone *"
                      option-label="label"
                      v-model="selTimezone"
                    />

                    <span class="error-message">{{ formErrors.timezone }}</span>
                    
                    <span class="error-message">{{ formErrors.content }}</span>
                  </q-card-section>

                </q-form>

              </div>

              <q-btn class="login" flat icon="navigate_before" label="Back to Login" :to="'/login/'" />

              <q-stepper-navigation>
                <q-btn @click="step = 1" color="altmedia-blue" icon="navigate_before" disable label="Previous" />
                <q-btn @click="validateAndProceedToStep2" color="altmedia-blue" icon-right="navigate_next" label="Next" />

              </q-stepper-navigation>
            </q-step>

            <q-step
              :name="2"
              title="Personal"
              caption="Personal information"
              icon="person"
              :done="step > 2"
              :header-nav="step > 2"
            >
            
              <div class="step-content">
                <q-form @submit.prevent="submitForm" class="qForm">
                  <q-card-section>
                    <div class="text-h6">Personal Information</div>
                    <div class="text-h7">Fill the below form to create a new account</div>
                  </q-card-section>

                  <q-separator inset />

                  <q-card-section class="column q-gutter-md">

                    <div class="form-row">
                      <span class="half-row">
                        <q-input v-model.trim="form.fname" label="First name *" />
                        <span class="error-message">{{ formErrors.fname }}</span>
                      </span>

                      <span class="half-row">
                        <q-input v-model.trim="form.lname" label="Last name *" />
                        <span class="error-message">{{ formErrors.lname }}</span>  
                      </span>
                    </div>

                    
                    <div class="form-row">
                      <span class="half-row">
                        <q-input v-model.trim="form.phone" label="Phone (need it for sending credentials)*" />
                        <span class="error-message">{{ formErrors.phone }}</span>
                      </span>
                      <span class="half-row">
                        <q-input v-model.trim="form.postcode" label="Postcode" />
                        <span class="error-message">{{ formErrors.postcode }}</span>    
                      </span>

                    </div>
                    
                    <q-input v-model.trim="form.address" label="Address *" />
                    <span class="error-message">{{ formErrors.address }}</span>

                    <q-input v-model.trim="form.company" label="Company" />
                    <span class="error-message">{{ formErrors.company }}</span>

                    <div class="form-row">
                      <span class="half-row">
                        <q-input v-model.trim="form.city" label="City *" />
                        <span class="error-message">{{ formErrors.city }}</span>
                      </span>
                      <span class="half-row">

                        <q-select
                          :options="optionsCountries"
                          label="Country *"
                          option-label="name" 
                          v-model="selCountry"
                        />

                        <span class="error-message">{{ formErrors.country }}</span>                  
                      </span>

                    </div>

                    <span class="error-message">{{ formErrors.content }}</span>
                  </q-card-section>

                </q-form>
              </div>

              <q-btn class="login" flat icon="navigate_before" label="Back to Login" :to="'/login/'" />

              <q-stepper-navigation>
                <q-btn @click="step = 1" color="altmedia-blue"  label="Previous" icon="navigate_before" />
                <q-btn @click="validateAndProceedToStep3" color="altmedia-blue" icon-right="navigate_next" label="Next" />
              </q-stepper-navigation>
            </q-step>

            <q-step
              :name="3"
              title="Confirm"
              caption="Submit"
              icon="topic"
              :header-nav="step > 3"
            >
              <div class="step-content">
                <div class="summary">
                  <div class="section">
                    <div class="label">Email:</div>
                    <div class="value">{{ form.email }}</div>
                  </div>
                  <div class="section">
                    <div class="label">Timezone:</div>
                    <div class="value">{{ selTimezone.label }}</div>
                  </div>
                  <div class="section">
                    <div class="label">First Name:</div>
                    <div class="value">{{ form.fname }}</div>
                  </div>
                  <div class="section">
                    <div class="label">Last Name:</div>
                    <div class="value">{{ form.lname }}</div>
                  </div>
                  <div class="section">
                    <div class="label">Phone:</div>
                    <div class="value">{{ form.phone }}</div>
                  </div>
                  <div class="section">
                    <div class="label">Postcode:</div>
                    <div class="value">{{ form.postcode }}</div>
                  </div>
                  <div class="section">
                    <div class="label">Address:</div>
                    <div class="value">{{ form.address }}</div>
                  </div>
                  <div class="section">
                    <div class="label">Company:</div>
                    <div class="value">{{ form.company }}</div>
                  </div>
                  <div class="section">
                    <div class="label">City:</div>
                    <div class="value">{{ form.city }}</div>
                  </div>
                  <div class="section">
                    <div class="label">Country:</div>
                    <div class="value">{{ selCountry.name }}</div>
                  </div>
                </div>

                <div v-if="displayRecaptcha" class="captchaBox">
                  <div class="preview" ref="captchaPreview"></div>
                  <input v-model.trim="userInput">
                  <button class="captcha-refresh" @click="refreshCaptcha">Refresh</button>
                  <p v-if="incorrectCaptcha" class="error-message">Incorrect CAPTCHA. Please try again.</p>
                </div>
              </div>

              <q-btn class="login" flat icon="navigate_before" label="Back to Login" :to="'/login/'" />
              <q-stepper-navigation>
                <q-btn @click="step = 2" color="altmedia-blue" icon="navigate_before" label="Previous" />
                <q-btn color="positive" @click="submitForm" icon="done" label="Submit" />
              </q-stepper-navigation>
            </q-step>


          </q-stepper>
        </div>
            
      </div>

    </div>

  </div>
</template>

<script>
  import { getRestEndpoint } from '../utils/utils'
  import axios from 'axios'

  export default {
    name: 'register-user',
    data () {
      return {
        REST_ENDPOINT: '',
        optionsCountries: [],
        selTimezone: null,
        
        selCountry: '',
        displayRecaptcha: true, 

        fonts: ["cursive", "sans-serif", "serif", "monospace"],
        captchaCode: '',
        userInput: '',
        incorrectCaptcha: false,

        step: 1,
        done1: false,
        done2: false,
        done3: false,
        
        form: {
          usernamePortal: '',
          password: ''
        },
        formErrors: {},
      }
    },

    methods: {

      getCountries() {
        axios
          .get(`${this.REST_ENDPOINT}utils/countries`)
          .then((response) => {
            this.optionsCountries = response.data
          })
        ;
      },

      async checkShowCaptcha() {
        try {
          const response = await axios.get(`${this.REST_ENDPOINT}register/check-captcha`);
          this.displayRecaptcha = response.data.displayRecaptcha;
          
          if (this.displayRecaptcha) {
            this.generateCaptcha();
          }
        } catch (error) {
          console.error('Error checking captcha:', error);
        }
      },

      generateCaptcha() {
        let value = Math.random().toString(36).substr(2, 5).toUpperCase();
        this.captchaCode = value;
      },
      
      setCaptcha() {
        const captchaPreview = this.$refs.captchaPreview;
        if (captchaPreview) {
          const html = this.captchaCode.split("").map(char => {
            const rotate = -40 + Math.trunc(Math.random() * 80);
            const fontSize = 18 + Math.trunc(Math.random() * 6);
            const letterSpacing = Math.trunc(Math.random() * 4);
            const font = Math.trunc(Math.random() * this.fonts.length);
            return `<span style="display: inline-block; transform: rotate(${rotate}deg); font-family: ${this.fonts[font]}; font-size: ${fontSize}px; margin-right: ${letterSpacing}px;">${char}</span>`;
          }).join("");
          captchaPreview.innerHTML = html;
        }
      },

      refreshCaptcha() {
        this.generateCaptcha();
        this.setCaptcha();
        this.userInput = '';
        this.incorrectCaptcha = false;
      },

      checkCaptcha() {
        if (this.userInput.toUpperCase() === this.captchaCode) {
          this.incorrectCaptcha = false;
          this.refreshCaptcha();
        } else {
          this.incorrectCaptcha = true;
        }
      },

      validateAndProceedToStep2() {

        this.formErrors.email = '';
        this.formErrors.email2 = '';
        this.formErrors.timezone = '';

        if (!this.form.email) {
          this.formErrors.email = 'Email is required';
        } else if (!this.isEmailValid(this.form.email)) {
          this.formErrors.email = 'Invalid email format';
        }

        if (!this.form.email2) {
          this.formErrors.email2 = 'Confirm Email is required';
        } else if (this.form.email !== this.form.email2) {
          this.formErrors.email2 = 'Emails do not match';
        } else {

          this.isEmailUsed(this.form.email)
            .then(valid => {
              if (valid) {
                if (!this.selTimezone) {
                  this.formErrors.timezone = 'Timezone is required';
                } else {
                  this.step = 2;
                }
              } else {
                this.formErrors.email = 'Email already registered';
              }
            })
            .catch(error => {
              console.error('Error checking email:', error);
              this.formErrors.email = 'Error checking email';
            });
        }
      },

      validateAndProceedToStep3() {
        
        this.formErrors.fname = '';
        this.formErrors.lname = '';
        this.formErrors.phone = '';
        this.formErrors.postcode = '';
        this.formErrors.address = '';
        this.formErrors.company = '';
        this.formErrors.city = '';
        this.formErrors.country = '';

        if (!this.form.fname) {
          this.formErrors.fname = 'First Name is required';
        } else if (this.form.fname.length < 3) {
          this.formErrors.fname = 'First Name should have at least 3 characters';
        } else if (this.form.fname.length > 20) {
          this.formErrors.fname = 'First Name should not exceed 20 characters';
        }

        if (!this.form.lname) {
          this.formErrors.lname = 'Last Name is required';
        } else if (this.form.lname.length < 3) {
          this.formErrors.lname = 'Last Name should have at least 3 characters';
        } else if (this.form.lname.length > 20) {
          this.formErrors.lname = 'Last Name should not exceed 20 characters';
        }

        if (!this.form.phone) {
          this.formErrors.phone = 'Phone is required';
        } else if (this.form.phone.length < 3) {
          this.formErrors.phone = 'Phone should have at least 3 characters';
        } else if (this.form.phone.length > 20) {
          this.formErrors.phone = 'Phone should not exceed 20 characters';
        }

        if (this.form.postcode) {
          if (this.form.postcode.length > 10) {
            this.formErrors.postcode = 'Postcode should not exceed 10 characters';
          }
        }

        if (!this.form.address) {
          this.formErrors.address = 'Address is required';
        } else if (this.form.address.length < 3) {
          this.formErrors.address = 'Address should have at least 3 characters';
        } else if (this.form.address.length > 255) {
          this.formErrors.address = 'Address should not exceed 255 characters';
        }

        if (this.form.company) {
          if (this.form.company.length > 100) {
            this.formErrors.company = 'Company should not exceed 100 characters';
          }
        }
        
        if (!this.form.city) {
          this.formErrors.city = 'City is required';
        } else if (this.form.city.length < 3) {
          this.formErrors.city = 'City should have at least 3 characters';
        } else if (this.form.city.length > 50) {
          this.formErrors.city = 'City should not exceed 50 characters';
        }

        if (!this.selCountry) {
          this.formErrors.country = 'Country is required';
        }

        const step3Errors = Object.values(this.formErrors).filter(error => error !== '');
        
        if (step3Errors.length === 0) {
          this.step = 3;
        }
      },

      submitForm() {

        if (this.userInput.toUpperCase() !== this.captchaCode) {
          this.incorrectCaptcha = true;
          return;
        }

        const userData = {
          email: this.form.email,
          timezone: this.selTimezone.value,
          fname: this.form.fname,
          lname: this.form.lname,
          phone: this.form.phone,
          postcode: this.form.postcode,
          address: this.form.address,
          company: this.form.company,
          city: this.form.city,
          country: this.selCountry.id
        };

        axios
          .post(`${this.REST_ENDPOINT}register`, userData)
          .then(() => {
            this.loading = false;
            this.$router.push('/login?message=User registered, please check your email to verify address.');
          })
          .catch((error) => {
            console.error(error);
          });

        this.$refs.stepper.next();
        
      },
      
      isEmailValid(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      },
      async isEmailUsed() {
        try {
          const response = await axios.post(`${this.REST_ENDPOINT}register/check-email`, { email: this.form.email });
          
          if (response.status === 200) {
            this.formErrors.email = '';
            return true;
          }
        } catch (error) {
          if (error.response && error.response.status === 400 && error.response.data.message === 'Email already registered') {
            this.formErrors.email = 'Email already registered';
          } else {
            console.error('Error checking email:', error);
          }
        }
        
        return false;
      },

    },
    
    mounted() {
      this.REST_ENDPOINT = getRestEndpoint();
      this.getCountries();

      if (!this.selTimezone) {
        this.selTimezone = this.optionsTimezone.find(timezone => timezone.value === 'UTC+2');
      }

      this.checkShowCaptcha();
    },

    updated() {
      if (this.displayRecaptcha) {
        this.$nextTick(() => {
          this.setCaptcha();
        });
      }
    },

    computed: {
      optionsTimezone() {
        return [
        { label: 'UTC-12', value: 'UTC-12' },
          { label: 'UTC-11', value: 'UTC-11' },
          { label: 'UTC-10', value: 'UTC-10' },
          { label: 'UTC-9', value: 'UTC-9' },
          { label: 'UTC-8', value: 'UTC-8' },
          { label: 'UTC-7', value: 'UTC-7' },
          { label: 'UTC-6', value: 'UTC-6' },
          { label: 'UTC-5', value: 'UTC-5' },
          { label: 'UTC-4', value: 'UTC-4' },
          { label: 'UTC-3', value: 'UTC-3' },
          { label: 'UTC-2', value: 'UTC-2' },
          { label: 'UTC-1', value: 'UTC-1' },
          { label: 'UTC', value: 'UTC' },
          { label: 'UTC+1', value: 'UTC+1' },
          { label: 'UTC+2', value: 'UTC+2' },
          { label: 'UTC+3', value: 'UTC+3' },
          { label: 'UTC+4', value: 'UTC+4' },
          { label: 'UTC+5', value: 'UTC+5' },
          { label: 'UTC+6', value: 'UTC+6' },
          { label: 'UTC+7', value: 'UTC+7' },
          { label: 'UTC+8', value: 'UTC+8' },
          { label: 'UTC+9', value: 'UTC+9' },
          { label: 'UTC+10', value: 'UTC+10' },
          { label: 'UTC+11', value: 'UTC+11' },
          { label: 'UTC+12', value: 'UTC+12' },
        ];
      }
    }
  }
</script>

<style lang="scss">
@import '../styles/styles.scss';

  .register-page {
    background-color: #f5f5f5;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .register-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      background-color: #fff;

      .image-container {
        display: flex;
        justify-content: center; 
        align-items: center; 
        height: 100%;
        width: 300px;
      }

      .form-container {
        flex: 1;
        background-color: #fff;
        height: 100%;
        padding: 100px;

        .q-stepper {
          box-shadow: none;

            .step-content {
              border: none;
              margin-bottom: 20px;

              .summary {
                display: flex;
                flex-wrap: wrap;

                .section {
                  width: calc(50% - 20px);
                  margin-right: 20px;
                  margin-bottom: 20px;

                  .label {
                    font-weight: bold;
                    color: #5e5873;
                  }
                }
              }

              .form-row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1rem;

                .half-row {
                  width: 48%;
                }
              }
            }

            .q-gutter-y-md > *, .q-gutter-md > * {
              margin-top: 2px !important; 
            }

            .q-field--labeled .q-field__native, .q-field--labeled .q-field__prefix, .q-field--labeled .q-field__suffix {
              padding-bottom: 5px;
            }

            .q-form {
              box-shadow: none;
            }

            .login {
              color: $altmedia-base-color;
              text-transform: initial;
              padding: 0;
            }

            .q-card__section--vert {
              padding: 16px 0;
            }

            .q-stepper__nav {
              display: flex;
              justify-content: space-between;
              padding-top: 10px;

              .q-btn {
                text-transform: initial;
              }
            }

            .q-stepper__step-inner {
              padding: 24px 0;
            
            }

            .q-stepper__tab {
              padding: 0;
            }

        }

        .form-text {
          display: flex;
          flex-direction: column;
          .form-title {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: bold;
            margin: 20px auto;
            color: #5e5873;
            
          }
          .form-subtitle {
            margin: 0 auto 40px;
            font-size: 1rem;
            color: #5e5873;
            margin-bottom: 1rem;
          }
        }

        .form-group {
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;

          label {
            font-weight: bold;
            margin-bottom: 0.5rem; 
            color: #5e5873;
            font-size: .857rem;
          }

          input {
            padding: 0.5rem;
            border: 1px solid $altmedia-base-color;
            border-radius: 5px;
            font-size: 1rem;
          }

          .error {
            color: red;
            font-size: 0.8rem;
            margin-top: 0.5rem;
          }
        }

        .register {
          font-size: 1rem;
          margin-top: 1rem;
          a {
            color: $altmedia-base-color;
          }
        }

        .submitBtn {
          width: 100%;  
        }

        .register-error {
          width: 100%;
          display: block;
          margin-top: 20px;
          font-size: 1rem;
          color: red;
        }
      }
    }
  }
</style>