<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Contact Groups" icon="groups" to="/contact-groups" />
      <q-breadcrumbs-el label="Add Contact Group" icon="add" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>
  
  <q-card class="quick-send-card">
    <q-form @submit.prevent="submitForm" class="qForm">
      <q-card-section>
        <div class="text-h6">Add Contact Group</div>
      </q-card-section>

      <q-separator inset />

      <q-card-section class="column q-gutter-md">

        <q-input v-model.trim="form.name" label="Name *" />
        <span class="error-message">{{ formErrors.name }}</span>

      </q-card-section>

      <q-card-actions>
        <q-btn icon="add" color="altmedia-blue" label="Add Contact Group" @click="submitForm()"></q-btn>
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script>
import { getRestEndpoint } from '@/utils/utils'
import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
import axios from "axios";

export default {
  name: 'AddContactGroup',

  data() {
    return {
      REST_ENDPOINT: '',
      form: {
        name: ''
      },
      formErrors: {
        name: ''
      },
    }
  },
  created() {
    handleClientTokenExpiration(this.$router);
  },
  methods: {
    async submitForm() {
      this.validateForm();
      if (this.isFormValid()) {
        try {
          await axios.post(`${this.REST_ENDPOINT}contact-groups`, {
            name: this.form.name,
          }, { withCredentials: true });
          this.$router.push('/contact-groups');
        } catch (error) {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        }
      }
    },
    validateForm() {
      if (!this.form.name) {
        this.formErrors.name = 'Name field is required'
      } else {
        this.formErrors.name = ''
      }
    },
    isFormValid() {
      return Object.values(this.formErrors).every((error) => !error)
    },
  },
  mounted() {
    this.REST_ENDPOINT = getRestEndpoint();

  },
}
</script>

<style lang="scss">
  
</style>