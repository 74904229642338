import decode from 'jwt-decode'
import axios from 'axios'
import store from '../store'

const AUTH_TOKEN_KEY = 'authToken'

export function loginUser(authToken, username) {
    setAuthToken(authToken)
    setUserDetails(username)
}

export function logoutUser() {
    store.commit('setUsername', '')
    store.commit('toggleLogin', false)
    clearAuthToken()
    clearUserDetails()
}

function setUserDetails(username) {
    localStorage.setItem('username', username)
}

export function getUserDetails() {
    let username = localStorage.getItem('username')
    return {
        username,
    }
}

function clearUserDetails() {
    localStorage.removeItem('username')
}

export function setAuthToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export function getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY)
}

export function clearAuthToken() {
    axios.defaults.headers.common['Authorization'] = ''
    localStorage.removeItem(AUTH_TOKEN_KEY)
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    return !!authToken && !isTokenExpired(authToken)
}

export function getUserInfo() {
    if (isLoggedIn()) {
        return decode(getAuthToken())
    }
}

function getTokenExpirationDate(encodedToken) {
    let token = decode(encodedToken)
    if (!token.exp) {
        return null
    }
  
    let date = new Date(0)
    date.setUTCSeconds(token.exp)
  
    return date
}
  
export function isTokenExpired() {
    let expirationDate = getTokenExpirationDate(getAuthToken())
    return expirationDate < new Date()
}

export function isAuthenticated() {
    const token = getAuthToken();
    return token !== null;
}

export function handleClientTokenExpiration(router) {
    const excludedRoutes = ['/register', '/password', '/verify', '/'];

    if (!isAuthenticated() || isTokenExpired()) {
        const currentRoute = router.currentRoute.value.path;
        if (!excludedRoutes.includes(currentRoute)) {
            const message = 'Your session has expired. Please log in again.';
            router.push({ path: '/login', query: { message: encodeURIComponent(message) } });
        } 
    }
}

export function handleTokenExpiration(router) {
    const message = 'Your session has expired. Please log in again.';
    router.push({ path: '/login', query: { message: encodeURIComponent(message) } });
}