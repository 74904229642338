<template>
    <div class="q-pa-md q-gutter-sm">
      <q-breadcrumbs>
        <q-breadcrumbs-el icon="home" to="/" />
        <q-breadcrumbs-el label="Contact Groups" icon="groups" to="/contact-groups" />
        <q-breadcrumbs-el :label="contactGroupName" icon="person" :to="'/contact-group/' + contactGroupId" />
        <q-breadcrumbs-el label="Import Contacts" icon="unarchive" />
        <template v-slot:separator>
          <q-icon
            size="1.5em"
            name="chevron_right"
            color="primary"
          />
        </template>
      </q-breadcrumbs>
    </div>
    
    <q-card class="quick-send-card">
      <q-form @submit.prevent="submitForm" class="qForm">
        <q-card-section>
          <div class="text-h6">Import Contacts</div>
        </q-card-section>
  
        <q-separator inset />
  
        <q-card-section class="column q-gutter-md">
  
          <q-input type="textarea" v-model="form.contactsContent" label="Paste your contacts (Phone, First Name, Last Name - per line) *" />
          <span class="error-message">{{ formErrors.contactsContent }}</span>
          <span class="error-message">{{ formErrors.phoneNumber }}</span>
  
        </q-card-section>
  
        <q-card-actions>
          <q-btn icon="unarchive" color="altmedia-blue" label="Import Contacts" @click="submitForm()"></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </template>
  
  <script>
  import { getRestEndpoint } from '@/utils/utils'
  import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
  import axios from "axios";
  
  export default {
    name: 'ImportContacts',
  
    data() {
      return {
        contactGroupId: null,
        contactGroupName: null,
        contactItems: [],
        contactObjects: [],
        REST_ENDPOINT: '',
        options:[
              {label: ', (Comma)', value: ','},
              {label: '; (Semicolon)', value: ';'},
              {label: '| (Bar)', value: '|'},
              {label: '| (Tab)', value: '\t'},
              {label: '| (New line)', value: '\n'}
            ],
        form: {
          contactsContent: '',
          contactObjects: [],
        },
        formErrors: {
          contactsContent: '',
          phoneNumber: '',
        },
      }
    },
    created() {
      handleClientTokenExpiration(this.$router);
    },
    methods: {
      getContactGroupName() {
        axios
          .get(`${this.REST_ENDPOINT}contact-groups/details/${this.contactGroupId}`, { withCredentials: true })
          .then((response) => {
            this.contactGroupName = response.data;
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          });
      },




      async submitForm() {
        this.validateForm();
        if (this.isFormValid()) {
          let hasDuplicate = false; // Flag to track if a duplicate has been found

          for (const contact of this.contactObjects) {
            try {
              await axios.post(`${this.REST_ENDPOINT}contact`, {
                phone: contact.phone,
                first_name: contact.first_name,
                last_name: contact.last_name,
                cg_id: this.contactGroupId,
              }, { withCredentials: true });
              this.$router.push(`/contact-group/${this.contactGroupId}`);
            } catch (error) {
              if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
                handleTokenExpiration(this.$router);
              } else if (error.response && error.response.status === 400 && error.response.data && error.response.data.error) {
                if (!hasDuplicate) {
                  this.formErrors.phoneNumber = error.response.data.error;
                  hasDuplicate = true; // Set the flag to true to stop adding contacts
                }
              }
            }
          }
        }
      },






      validateForm() {
        if (!this.form.contactsContent) {
          this.formErrors.contactsContent = 'Contacts field (Phone, First Name, Last Name - per line) is required'
        } else {
          this.formErrors.contactsContent = ''
        }
        const contacts = this.form.contactsContent.split('\n');
        this.contactObjects = contacts.map((contacts) => {
            const [phone, first_name, last_name] = contacts.split(',');
            return { phone, first_name, last_name };
        });
        for (let i = 0; i < this.contactObjects.length; i++) {
          const item = this.contactObjects[i];
          if (!item.phone) {
            this.formErrors.contactsContent = 'Phone number is required'
          } else if (isNaN(item.phone)) {
            this.formErrors.contactsContent = 'Phone number is invalid'
          } else if (!item.first_name || item.first_name.length < 3) {
            this.formErrors.contactsContent = 'First name is required'
          } else if (!item.last_name || item.last_name.length < 3) {
            this.formErrors.contactsContent = 'Last name is required'
          } else {
            this.formErrors.contactsContent = ''
          }
        }
        return false;
      },
      isFormValid() {
        return Object.values(this.formErrors).every((error) => !error)
      },
    },
    mounted() {
      this.REST_ENDPOINT = getRestEndpoint();
      this.contactGroupId = this.$route.params.id;
      this.getContactGroupName();
    },
    
  }
  </script>
    
  <style lang="scss">
  
  </style>