<template>

  <q-dialog v-model="confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="delete" color="negative" text-color="white" />
        <span class="q-ml-sm">Are you sure?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="negative" v-close-popup />
        <q-btn flat label="Delete" color="positive" v-close-popup @click="removeSelectedItems" />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Contact Groups" icon="groups" to="/contact-groups" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>

  <div v-if="errorInfo" class="q-pa-md">
    <p class="error-message">Error: {{ errorInfo.error }}</p>
    <ul v-if="errorInfo.activeCampaigns" class="activeCampaigns">
      <li v-for="campaign in errorInfo.activeCampaigns" :key="campaign.id">
        - {{ campaign.name }}
      </li>
    </ul>

    <router-link
      v-if="errorInfo && errorInfo.activeCampaigns"
      class="q-ml-md view-campaigns-link"
      :to="`/reports-campaigns/contact-group/${errorInfo.groupId}`"
    >
      View Campaigns for this Group
    </router-link>
  </div>


  <div class="q-pa-md">
    <q-table
      title = "Contacts"
      :rows = "contactGroupsItems"
      :columns = "columns"
      row-key = "id"
      selection = "multiple"
      v-model:selected = "selected"
      :loading = "loading"
      :pagination="{ rowsPerPage: 20 }"
      color = "primary"
      :filter="filter"
    >

      <template v-slot:body-cell-name="scope">
        <q-td :props="scope">
          <router-link class="cg-details" :to="'/contact-group/' + scope.row.id">
            {{ scope.row.name }}
          </router-link>
        </q-td>
      </template>

      <template v-slot:body-cell-actions="scope">
        <q-td :props="scope">
          <q-btn flat round icon="add" color="blue" :to="'/contacts-add/' + scope.row.id">
            <q-tooltip class="blue">Add contact to this contact group</q-tooltip>
          </q-btn>
          <q-btn flat round icon="edit" color="positive" :to="'/contact-group-edit/' + scope.row.id">
            <q-tooltip class="bg-positive">Edit this contact group</q-tooltip>
          </q-btn>
          <q-btn flat round icon="delete" color="negative" @click="confirmDelete(scope.row.id)">
            <q-tooltip class="bg-negative">Delete this contact group</q-tooltip>
          </q-btn>
        </q-td>
      </template>

      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>
      
      <template v-slot:top-left>
        <q-btn color="negative" icon="delete" :disable="this.selected.length == 0" label="Remove selected" @click="confirm = true" />
        <q-btn color="positive" icon="add" class="q-ml-lg" label="Add contact group" to="/contact-group-add" />
        <q-btn
          class="q-ml-lg"
          color="altmedia-blue"
          icon="archive"
          label="Export"
          no-caps
          @click="exportTableCsv"
        />
        <q-space />
      </template>

      <template v-slot:top-right>
        <q-input  dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      
      </q-table>
  </div>
</template>
  
<script>
  import { getRestEndpoint, exportTable } from '@/utils/utils'
  import moment from 'moment';
  import axios from "axios";
  import { ref } from 'vue'
  import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';

  export default {
    name: 'ContactGroups',
    data() {
      return {
        REST_ENDPOINT: '',
        loading: false,
        filter: '',
        selected: [],
        contactGroupsItems: [],
        errorInfo: null,
        columns: [
          { name: 'name', align: 'left', label: 'Name', field: 'name', sortable: true, },
          { name: 'contacts', align: 'center', label: 'Contacts', field: 'num_contacts', sortable: true, },
          { name: 'created_at', align: 'center', label: 'Created', field: 'created_at', sortable: true, format: (val) => moment(val).fromNow() },
          { name: 'updated_at', align: 'center', label: 'Updated', field: 'updated_at', sortable: true, format: (val) => moment(val).fromNow() },
          { name: 'actions', align: 'center', label: 'Actions', sortable: false, style: 'width: 150px' },
        ]
      }
    },
    created() {
      handleClientTokenExpiration(this.$router);
    },
    methods: {
      confirmDelete(id) {
        if (window.confirm('Are you sure you want to delete this contact group?')) {
          this.removeItem(id); // Call the removeItem method if the user confirms
        }
      },
      formatDate(date) {
        return moment(date).fromNow();
      },
      exportTableCsv() {
        const columnsWithoutActions = [...this.columns];
        columnsWithoutActions.pop();
        exportTable(columnsWithoutActions, this.contactGroupsItems, 'contact_groups_')
      },
      readDataFromAPI() {
        this.loading = true;
        axios
          .get(`${this.REST_ENDPOINT}contact-groups`, { withCredentials: true })
          .then((response) => {
            this.loading = false;
            this.contactGroupsItems = response.data;
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          });
      },
      async removeItem(id) {
        try {
          await axios.delete(`${this.REST_ENDPOINT}contact-groups/${id}`, { withCredentials: true });
          this.contactGroupsItems = this.contactGroupsItems.filter((item) => item.id !== id);
        } catch (error) {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          } else {
            this.errorInfo = error.response.data;
          }
        }
      },
      async removeSelectedItems() {
        const successfulDeletions = [];
        for (const item of this.selected) {
          try {
            await this.removeItem(item.id);
            successfulDeletions.push(item.id);
          } catch (error) {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
              return; // Stop further processing on token expiration
            } else {
              this.errorInfo = error.response.data;
              break; // Stop further processing on error
            }
          }
        }
        this.selected = []; // Clear the selection after successful deletion
      },
    },
    mounted() {
      this.REST_ENDPOINT = getRestEndpoint();
      this.readDataFromAPI();
    },
    setup () {
      return {
        confirm: ref(false),
      }
    }
  }
</script>
  
<style lang="scss">
  @import '../styles/styles.scss';
  .cg-details {
    text-decoration: none;
    font-family: Montserrat, Helvetica, Arial, serif;
    color: #027be3;
    font-weight: 400;
    font-size: 14px;
  }

  .activeCampaigns {
    list-style-type: none;
  }

  .view-campaigns-link {
    color: $altmedia-base-color;
  }
</style>