<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Contact Groups" icon="groups" to="/contact-groups" />
      <q-breadcrumbs-el :label="contactGroupName" icon="person" :to="'/contact-group/' + contactGroupId" />
      <q-breadcrumbs-el label="Edit Contact" icon="edit" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>
  
  <q-card class="">
    <q-form @submit.prevent="submitForm" class="qForm">
      <q-card-section>
        <div class="text-h6">Edit Contact</div>
      </q-card-section>

      <q-separator inset />

      <q-card-section class="column q-gutter-md">
        
        <q-input v-model.trim="form.phoneNumber" label="Phone number *" />
        <span class="error-message">{{ formErrors.phoneNumber }}</span>

        <q-input v-model.trim="form.firstName" label="First Name *" />
        <span class="error-message">{{ formErrors.firstName }}</span>

        <q-input v-model.trim="form.lastName" label="Last Name *" />
        <span class="error-message">{{ formErrors.lastName }}</span>

      </q-card-section>

      <q-card-actions>
        <q-btn icon="edit" color="altmedia-blue" label="Update Contact" @click="submitForm()"></q-btn>
      </q-card-actions>
    </q-form>
  </q-card>
</template>
  
<script>
  import { getRestEndpoint } from '@/utils/utils'
  import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
  import axios from "axios";
  
  export default {
  name: 'ContactsEdit',
  
  data() {
    return {
      contactId: null,
      contactGroupName: null,
      contactGroupId: null,
      REST_ENDPOINT: '',
      form: {
        phoneNumber: '',
        firstName: '',
        lastName: ''
      },
      formErrors: {
        phoneNumber: '',
        firstName: '',
        lastName: ''
      },
    }
  },
  created() {
    handleClientTokenExpiration(this.$router);
  },
  methods: {
    readDataFromAPI() {
      axios.get(`${this.REST_ENDPOINT}contact/${this.contactId}`, { withCredentials: true })
        .then(response => {
          this.form.phoneNumber = response.data.phone;
          this.form.firstName = response.data.first_name;
          this.form.lastName = response.data.last_name;
          this.contactGroupId = response.data.contact_group_id;
          this.contactGroupName = response.data.cg_name;
        })
        .catch((error) => {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },
    async submitForm() {
      this.validateForm();
      if (this.isFormValid()) {
        try {
          await axios.put(`${this.REST_ENDPOINT}contact/${this.contactId}`, {
            phone: this.form.phoneNumber,
            first_name: this.form.firstName,
            last_name: this.form.lastName,
          }, { withCredentials: true });
          this.$router.push(`/contact-group/${this.contactGroupId}`);
        } catch (error) {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          } else if (error.response && error.response.status === 400 && error.response.data && error.response.data.error) {
            this.formErrors.phoneNumber = error.response.data.error;
          }
        }
      }
    },
    validateForm() {
      if (!this.form.phoneNumber) {
        this.formErrors.phoneNumber = 'Phone Number field is required';
      } else if (isNaN(this.form.phoneNumber)) {
        this.formErrors.phoneNumber = 'Phone Number is invalid';
      } else {
        this.formErrors.phoneNumber = '';
      }

      if (!this.form.firstName) {
        this.formErrors.firstName = 'First Name field is required'
      } else {
        this.formErrors.firstName = ''
      }

      if (!this.form.lastName) {
        this.formErrors.lastName = 'Last Name field is required'
      } else {
        this.formErrors.lastName = ''
      }
    },
    isFormValid() {
      return Object.values(this.formErrors).every((error) => !error)
    },      
  },
  mounted() {
    this.contactId = this.$route.params.id;
    this.REST_ENDPOINT = getRestEndpoint();
    this.readDataFromAPI();
  },
  
  }
</script>
  
<style lang="scss">

</style>