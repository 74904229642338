<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="SMS Templates" icon="sms" to="/templates-sms" />
      <q-breadcrumbs-el label="Add Template" icon="add" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>
  
  <q-card class="quick-send-card">
    <q-form @submit.prevent="submitForm" class="qForm">
      <q-card-section>
        <div class="text-h6">Add Template</div>
      </q-card-section>

      <q-separator inset />

      <q-card-section class="column q-gutter-md">

        <q-input v-model.trim="form.name" label="Name *" />
        <span class="error-message">{{ formErrors.name }}</span>

        <q-input @keyup='charCount()' type="textarea" v-model.trim="form.content" label="Content *" />
        <span class="">{{ totalCharWarning }}</span>
        <span class="error-message">{{ formErrors.content }}</span>
      </q-card-section>

      <q-card-actions>
        <q-btn icon="add" color="altmedia-blue" label="Add Template" @click="submitForm()"></q-btn>
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script>
import { getRestEndpoint, calculateSmsLengthAndCount } from '@/utils/utils';
import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
import axios from "axios";

export default {
  name: 'SmsTemplatesAdd',

  data() {
    return {
      REST_ENDPOINT: '',
      nameTotalChar: 200, // Max characters for name
      contentTotalChar: 1000,
      totalCharWarning: null,
      form: {
        name: '',
        content: '',
      },
      formErrors: {
        name: '',
        content: '',
      },
    }
  },
  created() {
    handleClientTokenExpiration(this.$router);
  },
  methods: {
    submitForm() {
      this.validateForm();
      if (this.isFormValid()) {
        axios
          .post(`${this.REST_ENDPOINT}sms-template`, {
            name: this.form.name,
            content: this.form.content
          }, { withCredentials: true })
          .then(() => {
            this.loading = false;
            this.$router.push('/templates-sms');
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          });
      }
    },

    validateForm() {
      if (!this.form.name) {
        this.formErrors.name = 'Name field is required';
      } else if (this.form.name.length > this.nameTotalChar) {
        this.formErrors.name = `Name must not exceed ${this.nameTotalChar} characters`;
      } else {
        this.formErrors.name = '';
      }

      if (!this.form.content) {
        this.formErrors.content = 'Content field is required';
      } else {
        this.formErrors.content = '';
      }
    },

    charCount: function() {
      this.totalCharWarning = calculateSmsLengthAndCount(this.form.content);
      if (this.form.content.length > this.contentTotalChar) {
        this.form.content = this.form.content.substring(0, this.contentTotalChar);
      }
    },

    isFormValid() {
      return Object.values(this.formErrors).every((error) => !error);
    },
  },

  mounted() {
    this.REST_ENDPOINT = getRestEndpoint();
  },
  
}
</script>

  
<style lang="scss">

</style>