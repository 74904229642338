<template>
  <div class="verify-page">


    <div class="verify-container">

      <div class="image-container">
        <img src="../assets/altmedia-login.png" alt="">
      </div>


          <div class="messages-container">

              <div v-if="loading">Verifying...</div>
              <div v-if="message && !alreadyVerified" style="white-space: pre-line;">{{ message }}</div>
              <div v-if="error">{{ error }}</div>
              <div v-if="expired"> 
                <br><button @click="resendEmail">Resend Verification Email</button>
              </div>
              <div v-if="alreadyVerified">
                Email already verified!
              </div>
              <br>
              <div>
                <q-btn class="login" flat icon="navigate_before" label="Back to Login" :to="'/login/'" />
              </div>

          </div>

    </div>

  </div>
</template>


<script>
import axios from 'axios';
import { getRestEndpoint } from '@/utils/utils';

export default {
  name: 'VerifyEmail',
  data() {
    return {
      token: '',
      loading: true,
      message: '',
      error: '',
      expired: false,
      alreadyVerified: false,
      REST_ENDPOINT: '',
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    this.REST_ENDPOINT = getRestEndpoint();
    if (!this.alreadyVerified) {
      this.verifyToken(this.token);
    } else {
      this.loading = false;
    }
  },
  methods: {
    async verifyToken(token) {
      try {
        if (!this.alreadyVerified) {
          const response = await axios.get(`${this.REST_ENDPOINT}register/verify?token=${token}`);
          const data = response.data;
          if (data.valid === true || data.valid === 'true') {
            this.message = `
              Thank you for registering with us. Our support team will send your login credentials via SMS to the phone number you provided during registration. Please allow some time for the SMS to arrive.
              If you do not receive the SMS within a couple of hours, please contact our support team for assistance at sms.noc@altmedia.ro
            `;
          } else {
            if (data.message === 'Invalid token') {
              this.error = 'Email verification failed: Invalid token.';
            } else if (data.message === 'Token expired') {
              this.expired = true;
              this.error = 'Email verification failed: Token has expired.';
            } else if (data.message === 'Email already verified!') {
              this.alreadyVerified = true;
            } else {
              this.error = 'Email verification failed: ' + data.message;
            }
          }
        }
      } catch (error) {
        this.error = 'An error occurred while verifying the token.';
        this.expired = true;
      } finally {
        this.loading = false;
      }
    },
    async resendEmail() {
      try {
        await axios.post(`${this.REST_ENDPOINT}register/resend-token`, { token: this.token });
        this.message = 'A new verification email has been sent. Please check your email.';
        this.error = '';
        this.expired = false;
      } catch (error) {
        console.error('Error resending verification email:', error);
      }
    },
  },
};
</script>

<style lang="scss">
@import '../styles/styles.scss';

.verify-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .verify-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 500px;
      background-color: #fff;
      padding: 30px;
      border-radius: 5px;

      .image-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        height: 450px;
        img {
          width: 600px;
          height: 450px;
          object-fit: cover;
          border-radius: 5px;
        }
      }

      .messages-container {
        text-align: justify;
        flex: 1;
        font-size: 16px;
        background-color: #fff;
        width: 500px;
        height: 450px;
        display: flex; 
        flex-direction: column; 
        justify-content: center;
        align-items: center;

        .login {
          margin-top: 20px;
        }
      }

    }
      
  }

</style>
