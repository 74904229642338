<template>

  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Reports" icon="code"  />
      <q-breadcrumbs-el label="Campaigns" icon="campaign" to="/reports-campaigns" />
      <q-breadcrumbs-el :label="campaignName" icon="campaign" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>

  <div class="q-pa-md camp-details">

    <div>
      <span class="label">Name:</span>
      <br />
      <span class="value">{{ campaignName }}</span>
    </div>

    <div>
      <span class="label">Sender Name:</span>
      <br />
      <span class="value">{{ senderName }}</span>
    </div>

    <div>
      <span class="label">Message:</span>
      <br />
      <span class="value" style="white-space: pre-line">{{ campaignMessage }}</span>
    </div>

    <div>
      <span class="label">Scheduled:</span>
      <br />
      <span class="value">{{ campaignSchedule ? 'Yes' : 'No' }}</span>
    </div>

    <template v-if="campaignStartDatetime">
      <div>
        <span class="label">Start date:</span>
        <br />
        <span class="value">{{ formatDate(campaignStartDatetime) }}</span>
      </div>
    </template>

    <template v-if="campaignEndDatetime">
      <div>
        <span class="label">End date:</span>
        <br />
        <span class="value">{{ formatDate(campaignEndDatetime) }}</span>
      </div>
    </template>

    <div>
      <span class="label">Frequency:</span>
      <br />
      <span class="value">{{ campaignFrequency }}</span>
    </div>

    <div>
      <span class="label">Disabled:</span>
      <br />
      <span class="value">{{ campaignDisabled ? 'Yes' : 'No' }}</span>
    </div>

    <template v-if="campaignDisabled">
      <div>
        <span class="label">Disabled at:</span>
        <br />
        <span class="value">{{ formatDate(campaignDisabledAt) }}</span>
      </div>
    </template>

    <div>
      <span class="label">Created at:</span>
      <br />
      <span class="value">{{ formatDate(campaignCreatedAt) }}</span>
    </div>

    <div>
      <span class="label">Contact Groups:</span>
      <br />
      <span class="value">
        <template v-if="contactGroups.length">
          <ul>
            <li v-for="group in contactGroups" :key="group.id">
              <a class="cgdetails" :href="getContactGroupLink(group.id)">{{ group.name }}</a>
            </li>
          </ul>
        </template>
        <template v-else>
          No contact groups associated with this campaign.
        </template>
      </span>
    </div>
  </div>

  <div class="camp-executions">
    <q-table
      title = "Campaign Executions"
      :rows = "campExecutionsItems"
      :columns = "campExecutionsColumns"
      row-key = "id"
      :loading = "loading"
      :pagination="{ rowsPerPage: 10 }"
      color = "primary"
      :filter="filter"
    >
    </q-table>
  </div>

</template>
  
<script>
  import { getRestEndpoint } from '@/utils/utils'
  import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
  import axios from "axios";
  import { ref } from 'vue'
  import moment from 'moment';

  export default {
    name: 'CampaignDetails',
    data() {
      return {
        campaignId: null,
        campaignName: null,
        senderName: null,
        campaignMessage: null,
        campaignSchedule: null,
        campaignStartDatetime: null,
        campaignEndDatetime: null, 
        campaignFrequency: null,
        campaignDisabled: null,
        campaignDisabledAt: null,
        campaignCreatedAt: null,
        selectedOptionsContactGroups: this.selectedOptionsContactGroups,
        contactGroups: [],
        campExecutionsItems: [],
        campExecutionsColumns: [
          { name: 'executed_at', align: 'left', label: 'Executed at', field: 'executed_at', sortable: true, format: (val) => moment(val).fromNow() },
          { name: 'created_status', align: 'left', label: 'Execution status', field: 'created_status', sortable: true },
          { name: 'metric1', align: 'left', label: 'Metric 1', field: 'metric1', sortable: true },
          { name: 'metric2', align: 'left', label: 'Metric 2', field: 'metric2', sortable: true },
          { name: 'metric3', align: 'left', label: 'Metric 3', field: 'metric3', sortable: true }

        ],
        loading: false,
        filter: null,
        REST_ENDPOINT: '',
      }
    },
    created() {
      handleClientTokenExpiration(this.$router);
    },
    methods: {
      getContactGroupLink(groupId) {
        return `${window.location.origin}/contact-group/${groupId}`;
      },
      formatDate(dateTime) {
        return moment(dateTime).format('YYYY-MM-DD HH:mm');
      },
      getCampaignDetails() {
        axios
          .get(`${this.REST_ENDPOINT}campaign/details/${this.campaignId}`, { withCredentials: true })
          .then((response) => {
            this.campaignName = response.data.name;
            this.senderName = response.data.sender_name;
            this.campaignMessage = response.data.message;
            this.campaignSchedule = response.data.schedule;
            this.campaignStartDatetime = response.data.start_datetime;
            this.campaignEndDatetime = response.data.end_datetime;
            this.campaignFrequency = response.data.frequency;
            this.campaignDisabled = response.data.disabled;
            this.campaignDisabledAt = response.data.disabled_at;
            this.campaignCreatedAt = response.data.created_at;
            this.campExecutionsItems = response.data.executions;
            this.contactGroups = response.data.contact_groups;
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          });
      }
    },
    mounted() {
      this.campaignId = this.$route.params.id;
      this.REST_ENDPOINT = getRestEndpoint();
      this.getCampaignDetails();
    },
    setup () {
      return {
        confirm: ref(false),
      }
    }
  }
</script>

<style lang="scss" scoped>
  .camp-details {
    font-family: Montserrat, Helvetica, Arial, serif;
    font-size: 14px;
    color: #000;
    width: 600px;

    > div {
      margin-bottom: 20px;
      border: 1px solid #8db6ca;
      padding: 20px 10px;
      border-radius: 5px;
    }

    .label {
      font-weight: 600;
    }

    .value {
      font-weight: 400;
      
    }

    .cgdetails {
      color: #000;
        color: #008dd2;
        text-decoration: none;
        text-decoration: underline;
      } 
  }

  .camp-executions {
    border: none;
    padding: 15px;
    border-radius: 5px;
  }
</style>